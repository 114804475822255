import { request } from "@/request/http";


export default 
{
  getDevelopUserList: (data) => {
    return request("POST", `/index.php/adminDevelopUser/getDevelopUserList`, data);
  },

  getUserByName: (data) => {
    return request("POST", `/index.php/adminDevelopUser/getUserByName`, data);
  },

  updateDevelopUserData: (data) => {
    return request("POST", `/index.php/adminDevelopUser/updateDevelopUserData`, data);
  },

  getDevelopUserDataById: (data) => {
    return request("POST", `/index.php/adminDevelopUser/getDevelopUserDataById`, data);
  },

  delDevelopUserDataById: (data) => {
    return request("POST", `/index.php/adminDevelopUser/delDevelopUserDataById`, data);
  },
};