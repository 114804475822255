<template >
    <div class="developerList">
        <div class="logButton clearFix">
            <div class="export">
                <el-button class="themeButton" @click="showDialog">添加开发者</el-button>
            </div>
            <div class="search">
                <!-- 搜索题目 -->
                <div class="searchContent">
                    <el-input placeholder="请输入开发者姓名" clearable @clear="getData" v-model="searchContent" class="input-with-select" ></el-input>
                </div> 
                <el-button class="themeButton" @click="handleSearchColleague">搜索</el-button>
            </div>
        </div>
        <!-- 题目列表 -->
        <el-card>
            <el-table
                ref="multipleTable"
                :data="explabList"
                tooltip-effect="dark"
                style="width: 100%">
                    <!-- 序号 -->
                    <el-table-column
                      type="index"
                      :index="indexMethod"
                      label="序号"
                      width="100">
                    </el-table-column>
                    <!-- 序号 -->
                    <!-- <el-table-column
                      label="排序"
                      width="100">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.paixu" @blur="updateSortBy(scope.row.id,scope.row.paixu)"></el-input>
                      </template>
                    </el-table-column> -->
                    <!-- 头像 -->
                    <el-table-column
                      label="姓名"
                      prop="name">
                    </el-table-column>
                    <el-table-column
                      label="项目数量"
                      prop="expNumber">
                    </el-table-column>
                    <el-table-column
                      label="学校"
                      prop="school_name">
                    </el-table-column>
                    <!-- <el-table-column
                      label="备注"
                      prop="desc">
                    </el-table-column> -->
                    <!-- <el-table-column
                      label="帮助链接"
                      prop="article_id">
                    </el-table-column> -->
                    <!-- 操作-->
                    <el-table-column
                    label="操作">
                        <template slot-scope="scope">
                            <div class="operatorItem floatLeft" @click="getExpLabDataById(scope.row.userid)">编辑</div>
                            <div class="operatorItem floatLeft" @click="del(scope.row.userid,scope.row.name)">删除</div>
                        </template>
                    </el-table-column>
            </el-table>
            <!-- 题目分页 -->
            <el-pagination
                @current-change="getData"
                :current-page.sync="currentPage"
                background
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="explabListTotal">
            </el-pagination>
        </el-card>
            <el-dialog
            :title="title"
            :visible.sync="dialogVisible"
            width="60%">
                <span  class="dialog-span">
                    <el-form ref="form" :model="form" label-width="80px">
                       <el-form-item label="开发者">
                            <el-select
                                :disabled="!sign"
                                v-model="form.userid"
                                filterable
                                remote
                                clearable
                                :loading="loading"
                                placeholder="请搜索选择开发者！"
                                :remote-method="getUserByName">
                                <el-option
                                    v-for="item in articleList"
                                    :key="item.id"
                                    :label="item.str"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="简介">
                            <!-- <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 8}" placeholder="请输入备注" v-model="form.desc"></el-input> -->
                            <vue-ueditor-wrap
                                v-model="form.desc"
                                :config="myConfig"
                            ></vue-ueditor-wrap>
                        </el-form-item>
                    </el-form>
                </span>
            <span
                slot="footer"
                class="dialog-footer">
                <el-button class="themecancelButton" @click="dialogVisible = false">取 消</el-button>
                <el-button class="themeButton" @click="confirmAdd">确 定</el-button>
            </span>
        </el-dialog>
    </div>
  
</template>

<script>
    // 引入试题列表js
    import developerList from "@/assets/js/manager/exp/developerList.js";
    export default {
     ...developerList
    }
</script>
<style>
.developerList .el-table .el-table__cell {
    padding: .55vw 0 !important; 
}
.developerList .edui-default .edui-dialog {
    z-index: 210000000 !important;
    position: fixed !important;
}
.developerList .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
}
</style>
<style lang="scss" scoped>
// 引入试题列表组件css
    @import "@/assets/css/manager/exp/developerList.scss";
</style>