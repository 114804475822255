
import API from '@/api/manager/exp/developerList.js';
import VueUeditorWrap from "vue-ueditor-wrap";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    // 页面名称
    name: "developerList",
    components: {
        VueUeditorWrap
    },
    data() {
        return {
            // ueditor 编辑器配置项
            myConfig: {
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 初始容器高度
                initialFrameHeight: 100,
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: "./Editor/",
                // 编辑器不自动被内容撑高
                // autoHeightEnabled: false,
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                // serverUrl: editorUploadUrl,
                // 关闭自动保存
                enableAutoSave: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                [
                    "inserttable",//表格
                    "source", //源代码
                    "bold", //加粗
                    "italic", //斜体
                    "underline", //下划线
                    "strikethrough", //删除线
                    "fontborder", //字符边框
                    "blockquote", //引用
                    "selectall", //全选
                    "horizontal", //分隔线
                    "removeformat", //清除格式
                    "unlink", //取消链接
                    "deletecaption", //删除表格标题
                    "inserttitle", //插入标题
                    "cleardoc", //清空文档
                    "insertcode", //代码语言
                    "fontfamily", //字体
                    "fontsize", //字号
                    "insertimage", //多图上传
                    "link", //超链接
                    "emotion", //表情
                    "spechars", //特殊字符
                    "searchreplace", //查询替换
                    "insertvideo", //视频
                    "justifyleft", //居左对齐
                    "justifyright", //居右对齐
                    "justifycenter", //居中对齐
                    "forecolor", //字体颜色
                    "insertorderedlist", //有序列表
                    "insertunorderedlist", //无序列表
                    "imageleft", //左浮动
                    "imageright", //右浮动
                    "attachment", //附件
                    "imagecenter", //居中
                    "lineheight", //行间距
                ],
                ],
            },
            // 虚拟实验分类详情
            form:{
                id:'',
                userid:'',
                desc:''
            },
            // 虚拟实验分类数据
            explabList: [],
            // 虚拟实验分类数据总数
            explabListTotal: 0,
            // 根据虚拟实验分类内容搜索
            searchContent: "",
            articleList: [],
            currentPage: 1,
            pageSize: 10,
            exp_id: '',
            dialogVisible: false,
            loading:true,
            title:'',
            sign:true
        }
    },
    created() {
        let self = this;
        self.searchContent = this.$router.currentRoute.query.search;
        self.currentPage = Number(this.$router.currentRoute.query.page);
        self.getData();
    },
    methods:
    {   
        getUserByName(e)
        {
            let self = this;
            // 请求参数
            let data = {
                search: e,
            };
            nprogressStart();
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.getUserByName(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if (result.code > 0) {
                        self.articleList = result.list
                        self.loading = false
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        showDialog()
        {
            this.sign = true;
            for (const key in this.form) {
                this.form[key] = ''
            }
            this.title="添加开发者";
            this.dialogVisible = true;
        },

        getData() {
            try {
                let self = this;
                // 请求参数
                let data = {
                    search: self.searchContent,
                    page:self.currentPage,
                    pagesize:self.pageSize
                };
                self.$router.push({
                    path: "/manager/exp/developerList",
                    query: {
                        page: self.currentPage,
                        search: self.searchContent,
                    },
                });
                nprogressStart();
                self.$Loading.show();
                // 获取虚拟实验分类请求
                API.getDevelopUserList(data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if (result.code > 0) {
                            self.explabList = result.list
                            self.explabListTotal = result.number - 0
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },

        del(id,name) {
            try {
                let self = this;
                // 请求参数
                let data = {
                    id:id
                };
                // 删除同事请求
                self.$confirm(`确认要删除${name}吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    API.delDevelopUserDataById(data)
                        .then((result) => {
                            if (result.code > 0) {
                                // Message.success('删除成功');
                                self.getData();
                                this.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'success',
                                    displayTime: 1500
                                })
                            }
                        })
                }).catch(() => {
                    // Message.info('已取消');        
                });
            }
            catch (error) {
                // Message.error('系统错误');
            }
        },

        getExpLabDataById(id)
        {
            let self = this;
            self.sign = false;
            // 请求参数
            let data = {
                id: id,
            };
            // 修改虚拟实验分类数据请求
            API.getDevelopUserDataById(data)
                .then((result) => {
                    if (result.code > 0) 
                    {
                        self.articleList.push({
                            id:result.info.userid,
                            str:result.info.str,
                        })
                        self.form={...result.info};
                        self.title="修改开发者信息"
                        self.dialogVisible = true
                    }
                    else 
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        confirmAdd() {

            try {
                let self = this;
                // 请求参数
                let data = {
                    id: self.form.id,
                    userid:self.form.userid,
                    desc:self.form.desc,
                };
                if(!self.form.userid)
                {
                    return self.$Tips({
                        // 消息提示内容
                        message: '请选择开发者',
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        displayTime: 1500
                    })
                }
                // if(!self.form.name)
                // {
                //     return self.$Tips({
                //         // 消息提示内容
                //         message: '请输入分类名称',
                //         // 消息提示类型（success-成功,warning-警告/失败）
                //         messageType: 'warning',
                //         displayTime: 1500
                //     })
                // }
                // 添加/修改虚拟实验分类数据
                API.updateDevelopUserData(data)
                    .then((result) => {
                        if (result.code > 0) {
                            for (const key in self.form) {
                                self.form[key] = ''
                            }
                            self.dialogVisible = false;
                            self.getData();
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        else {
                            // Message.error('系统错误')
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },

        getArticleList(e) {
            try {
                let self = this;
                let data = {
                    search: e
                }
                if (e) {
                    API.getArticleList(data)
                        .then((result) => {
                            if (result.code > 0) {
                                self.articleList = result.articleList;
                                self.loading = false
                            }
                            else {
                                // Message.error('系统错误')
                                this.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'warning',
                                    displayTime: 1500
                                })
                            }
                        })
                }
            }
            catch (error) {
                // Message.error('系统错误')
            }
        },

        handleSearchColleague() 
        {
            let self = this;
            self.currentPage = 1;
            self.getData()
        },

        updateSortBy(id,sort_no)
        {
            let self = this;
            let data = {
                id: id,
                sort:sort_no
            }
            API.updateSortBy(data)
                .then((result) => {
                    if (result.code > 0) {
                        this.getData();
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    else {
                        // Message.error('系统错误')
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },

        indexMethod(index) 
        {
            let currentPage = this.currentPage - 0; // 当前页码
            let pageSize = this.pageSize - 0; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },
        }
};